import React from 'react';

export const FooterPDFPrint = () => {
    return (<>
        <table>
            <tr className="row" style={{ borderTop: '1px solid #d0d1d6', marginTop: '10px', }}>
                <td style={{ borderLeft: '15px solid #DAB451', width: '5%', maxWidth: '5%'}}>
                </td>
                <td>
                    <span style={{ textAlign: 'center' }}>
                        <h6>
                            GOING SRL – Sede Legale Piazzale Lotto 2 - 20148 Milano (MI) – Tel. 02.881261, Fax 02.87152977<br />
                            Capitale Sociale Euro 100.000,00 i.v. – P.IVA e Codice Fiscale 05197460966 – R.E.A. 1803899 – MI Società a Socio Unico<br />
                            Società soggetta a direzione e coordinamento di MSC Mediterranean Shipping Company SA – www.going.it<br />
                        </h6>
                    </span>
                </td>
                <td style={{ width: '5%', maxWidth: '5%' }}>
                </td>
            </tr>
        </table>
    </>
    )
}
