import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { M3Pax } from "../../Common/M3Pax";
import { ActivityDetailOption } from '../../Product/Activity/components/ActivityDetailOption';
import { getSearchParameterDate, getPassengerComposition, getCarpetStatus, TemplateInclusion, canStartSearch } from '../TemplateUtils';
import { generateClientFlowId } from '../../../js/ProductService.js';
import { imageOnLoadTemplateStep } from '../../../js/CDNUtility';
import { scrollTo, formatDateTime, formatPrice } from '../../../js/Utils.js';
import { searchCarpetParameter } from '../../../js/Constant';
import configData from "../../../appsettings.json";

export const ActivityTemplate = ({ templateData, products, selectedProducts, selectedProductData, searchParameters, isAlternative, isOptional, onSelectAlternative, onUpdateSelectedInfoData, onUnselectOptional, onFinishSearch }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    const [avlCarpetId, setAvlCarpetId] = useState(null);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState(null);
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);

    const [activityAvailability, setActivityAvailability] = useState(null);
    const [showChangeOptions, setShowChangeOptions] = useState(false);


    useEffect(() => {
        if (!avlCarpetId)
            return;

        callGetNewData(); // ping carpet to get results
    }, [avlCarpetId]);

    useEffect(() => {
        switch (carpetStatus) {
            case 'Error':
                setIsError(true);
                break;

            default: // 'Processing', 'Queued', 'Completed'
                break;
        }
    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                callGetNewData();
                clearCarpetInterval();
                getActivityAvailability();
                break;

            case 'NewData':
                callGetNewData();
                clearCarpetInterval();
                //getStructureAvailability();
                break;

            case 'NoChanges':
            case 'Processing':
                setCarpetInterval();
                break;

            default:
                break;
        }
    }, [carpetDataStatus]);

    useEffect(() => {
        let carpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.CarpetId : selectedProductData.AlternativeInfo.CarpetId
            : avlCarpetId;

        if (carpetId) {
            setIsLoading(true);
            setShowChangeOptions(false);
            getActivityAvailability();
        }
    }, [templateData]);

    useEffect(() => {
        if (searchStarted || !canStartSearch(templateData.templateDataId, products, selectedProducts))
            return;

        setIsLoading(true);
        setSearchStarted(true);
        createCarpetAvailability();
        onFinishSearch(templateData.templateDataId, true);
    }, [selectedProducts]);


    // carpet functions
    async function createCarpetAvailability() {
        let productSubType = 11; // default activity
        if (templateData.productSubType) {
            productSubType = templateData.productSubType;
        }

        let inputData = {
            carpetOperation: "AVL",
            clientFlowId: generateClientFlowId(),
            //userId: "",
            sessionToken: cookies.get("SessionToken"),
            websiteId: configData.Settings.WebsiteId,
            productSubType: productSubType,
            activitySearchParameters: {
                activityId: templateData.codiceProdotto,
                dateFrom: getSearchParameterDate(searchParameters, templateData, configData.Settings.Products.Activity.IdTipoProdotto, "CHECKIN"),
                dateTo: getSearchParameterDate(searchParameters, templateData, configData.Settings.Products.Activity.IdTipoProdotto, "CHECKOUT"),
                passengerComposition: getPassengerComposition(searchParameters, configData.Settings.Products.Activity.IdTipoProdotto),
                citizenship: searchParameters.citizenship,
                productSubType: productSubType,
                templateId: templateData.templateId
            }
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Activity/CreateCarpet', requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            setAvlCarpetId(response.carpetId);
            setIsError(false);
            onUpdateSelectedInfoData(templateData.templateDataId, response.carpetId, null, null, null, false, false);
        } else {
            setIsError(true);
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, true, true);
        }
    }
    async function callGetNewData() {
        if (carpetStatus === 'Completed')
            return;

        // conto quante chiamate sto facendo (ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato)
        let callTicks = carpetTime * searchCarpetParameter.activity.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.activity.carpetMaxTimeMs
        setCarpetTime(carpetTime + searchCarpetParameter.activity.carpetPingMs);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setCarpetInterval();
        } else {
            callGetCarpetStatus(avlCarpetId);
            clearCarpetInterval();
        }
    }
    async function callGetCarpetStatus() {
        let response = await getCarpetStatus(avlCarpetId);
        //console.log("CarpetStatus Response: " + JSON.stringify(response));

        if (!response.success) {
            setIsError(true);
        } else {
            let dataStatus = response.dataStatus;
            let status = response.status;
            setCarpetStatus(status);

            if (status !== 'Error') {
                // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges (lo status Processing esiste solo lato FE)
                setCarpetDataStatus(status !== carpetDataStatus ? dataStatus : 'Processing');
            }
        }
    }
    function setCarpetInterval() {
        if (!intervalCarpetPing) {
            //console.log("--- setCarpetInterval");
            let interval = setInterval(() => { callGetCarpetStatus(avlCarpetId); }, searchCarpetParameter.activity.carpetPingMs);
            setIntervalCarpetPing(interval);
        }
    }
    function clearCarpetInterval() {
        if (intervalCarpetPing) {
            //console.log("--- clearCarpetInterval");
            clearInterval(intervalCarpetPing);
            setIntervalCarpetPing(null);
        }
    }

    // availability functions
    async function getActivityAvailability() {
        let carpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.CarpetId : selectedProductData.AlternativeInfo.CarpetId
            : avlCarpetId;

        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            activityId: templateData.codiceProdotto,
            retrieveStaticData: true,
            //NumOptionsToView: 10,
            ShowAllOptions: true
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Activity/GetActivityAvailability`, requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.activity) {
                isError = false;

                setActivityAvailability(response.activity);

                let setDefaultOption = selectedProductData && (
                    (selectedProductData.SelectedInfo && !selectedProductData.SelectedInfo.ProductSelection) ||
                    (templateData.inclusion === TemplateInclusion.Optional && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.ProductSelection)
                );

                let productPriceDetail = {
                    DisplayPrice: response.activity.groupedOptions[0].pricing.displayPrice,
                    NetPrice: response.activity.groupedOptions[0].pricing.displayNet,
                    Markup: response.activity.groupedOptions[0].pricing.agencyMarkupVal,
                    Fee: response.activity.groupedOptions[0].pricing.agencyFee,
                    Commission: response.activity.groupedOptions[0].pricing.commission
                };

                if (setDefaultOption) {
                    // setto la option di default
                    let productSelection = response.activity.groupedOptions[0].id;
                    onUpdateSelectedInfoData(templateData.templateDataId, carpetId, null, productSelection, productPriceDetail, true, false);
                }

                let setDefaultAlternativePrice = selectedProductData && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.SelectedTotalPrice;
                if (setDefaultAlternativePrice) {
                    let productSelection = response.activity.groupedOptions[0].id;
                    onUpdateSelectedInfoData(templateData.templateDataId, carpetId, null, productSelection, productPriceDetail, true, false);
                }
            }
        }

        setIsError(isError);
        if (isError) {
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, true, true);
        }

        setIsLoading(false);
        onFinishSearch(templateData.templateDataId, false);
    }
    const selectOption = (data) => {
        let productSelection = data.optionCode;

        let productPriceDetail = { DisplayPrice: 0, NetPrice: 0, Markup: 0, Fee: 0, Commission: 0 };
        for (let i = 0; i < activityAvailability.groupedOptions.length; i++) {
            if (activityAvailability.groupedOptions[i].id === data.optionCode) {
                productPriceDetail.DisplayPrice = activityAvailability.groupedOptions[i].pricing.displayPrice;
                productPriceDetail.NetPrice = activityAvailability.groupedOptions[i].pricing.displayNet;
                productPriceDetail.Markup = activityAvailability.groupedOptions[i].pricing.agencyMarkupVal;
                productPriceDetail.Fee = activityAvailability.groupedOptions[i].pricing.agencyFee;
                productPriceDetail.Commission = activityAvailability.groupedOptions[i].pricing.commission;
            }
        }

        onUpdateSelectedInfoData(templateData.templateDataId, activityAvailability.carpetId, null, productSelection, productPriceDetail, true, false);
        setShowChangeOptions(!showChangeOptions);
        scrollTo(null, `templateDataId_${templateData.templateDataId}`);
    }


    // buttons
    const onButtonSelect = () => {
        // funzione usata quando si seleziona un prodotto opzionale o in alternativa, quindi viene selezionata la option con prezzo piu basso
        let productSelection = activityAvailability.groupedOptions[0].id;
        let productPriceDetail = {
            DisplayPrice: activityAvailability.groupedOptions[0].pricing.displayPrice,
            NetPrice: activityAvailability.groupedOptions[0].pricing.networkPrice,
            Markup: activityAvailability.groupedOptions[0].pricing.agencyMarkupVal,
            Fee: activityAvailability.groupedOptions[0].pricing.agencyFee,
            Commission: activityAvailability.groupedOptions[0].pricing.commission
        };
        onSelectAlternative(templateData.templateDataId, selectedProductData.SelectedId, productSelection, productPriceDetail, activityAvailability.carpetId);
    }
    const onButtonUnselect = () => {
        onUnselectOptional(templateData.templateDataId);
    }
    const toggleChangeOptions = (e) => {
        if (e) {
            e.preventDefault();
        }

        setShowChangeOptions(!showChangeOptions);
    }

    // pricing
    const getTotalPriceDiff = (groupedOptions) => {
        // funzione usata per calcolare la differenza di prezzo tra la option scelta e la best del prodotto opzionale/in alternativa
        let totalPrice = groupedOptions[0].pricing.displayPrice;

        if (!selectedProductData || !selectedProductData.SelectedTotalPrice) {
            if (isOptional) {
                let formattedTotalPrice = formatPrice(totalPrice, groupedOptions[0].pricing.valutaCliente, cultureName);
                return `+${formattedTotalPrice}`;
            }

            return (<></>);
        }

        let diff = totalPrice - selectedProductData.SelectedTotalPrice.DisplayPrice;
        if (diff === 0)
            return "+ " + formatPrice(diff, groupedOptions[0].pricing.valutaCliente, cultureName);

        let formattedPrice = formatPrice(diff, groupedOptions[0].pricing.valutaCliente, cultureName);

        return `${diff > 0 ? "+" : ""}${formattedPrice}`;
    }

    // render
    const renderSelectedOption = () => {
        if (isError)
            return (<h6 className="red">Prodotto non disponibile.</h6>);

        if (!activityAvailability || !activityAvailability.groupedOptions || activityAvailability.groupedOptions.length === 0)
            return (<></>);

        let selectedOptionId = null;
        if (!isAlternative && !isOptional && selectedProductData.SelectedInfo.ProductSelection) {
            selectedOptionId = selectedProductData.SelectedInfo.ProductSelection.split("|")[0];
        } else if (isOptional) {
            selectedOptionId = activityAvailability.groupedOptions[0].id;
        }

        let selectedGroupedOption = activityAvailability.groupedOptions.filter(x => x.id === selectedOptionId);
        if (!selectedGroupedOption || selectedGroupedOption.length === 0)
            return (<></>);

        let selectedOption = {
            OptionName: selectedGroupedOption[0].name,
            DisplayPrice: selectedGroupedOption[0].pricing.displayPrice,
            CancellationPolicy: selectedGroupedOption[0].cancellationPolicy,
            SupplierName: selectedGroupedOption[0].supplier ? selectedGroupedOption[0].supplier.supplierName : null,
            status: selectedGroupedOption[0].status
        };

        if (!isAlternative) {
            return (
                <React.Fragment>
                    <div className="w-100">
                        <label>
                            {t(`Activity:Option`)} <var>{selectedOption.OptionName}</var>
                        </label>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="penalty">
                                {selectedOption.CancellationPolicy &&
                                    <span className={selectedOption.CancellationPolicy.isFree ? "penalty free" : "penalty"}>
                                        <M3Icon iconName="CancelPolicy" externalClass="icon-10 mr-2" />
                                        {selectedOption.CancellationPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(selectedOption.CancellationPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}
                                    </span>
                                }
                            </label>
                        </div>
                        <div className="col-6 text-right">
                            {!isOptional && <span className="text-warning small d-block mr-2"><var>{selectedOption.SupplierName}</var></span>}
                        </div>
                        {selectedOption.status && selectedOption.status === 'OnRequest' && <div className="col-12 text-info">OnRequest</div>}
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className="w-100">
                        <label>
                            {t(`Activity:Option`)} <var>{selectedOption.OptionName}</var>
                        </label>
                    </div>
                </React.Fragment>
            );
        }
    }


    return (
        <>
            {templateData && (templateData.productSubType !== 41 || !isAlternative) &&
                <React.Fragment>
                    <div id={`templateDataId_${templateData.templateDataId}`} style={{ height: '0' }}>&nbsp;</div>

                    {debug &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <p style={{ width: '500px' }}>
                                {activityAvailability && activityAvailability.name}<br />
                                {selectedProductData && JSON.stringify(selectedProductData)}
                            </p>
                        </div>
                    }

                    {!isAlternative && !isOptional &&
                        <React.Fragment>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadTemplateStep()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="myBook-card-body">
                                    <div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                <span><data m3lab="Product.Activity.Item">{t(`Product:Activity:Item`)}</data></span>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4">
                                                <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                <div className="btn-group text-right float-end pr-2">
                                                    <button id={templateData.templateDataId} className="btn p-0 m-0" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <data m3ico="MenuFunctions">
                                                            <M3Icon iconName="MenuFunctions" />
                                                        </data>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="menuItem1">
                                                        {/*<li><a className="dropdown-item" href="#"><data m3ico="Activity icon-10 small rext-gray-600"><span className="material-icons icon-10 small text-gray-600">directions_walk</span></data><label><data m3lab="ActionMenu.ActivityChange">Cambio activity</data></label></a></li>*/}
                                                        <li>
                                                            <a id={`aActivityDetail_${templateData.product.activityId}`} href="#" onClick={e => scrollTo(e, "activityDetail_" + templateData.product.activityId)} className="dropdown-item">
                                                                <data m3ico="Info icon-10"><span className="material-icons icon-10">info_outline</span></data>
                                                                <label><data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data></label>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a id={`aActivityChangeOption_${templateData.product.activityId}`} href="#" onClick={e => toggleChangeOptions(e)} className="dropdown-item">
                                                                <data m3ico="Night"><M3Icon iconName="Night" externalClass="icon-10 small text-gray-600" /></data>
                                                                <label><data m3lab="ActionMenu.OptionChange">{t(`ActionMenu:OptionChange`)}</data></label>
                                                            </a>
                                                        </li>
                                                        {/*<li><a className="dropdown-item text-danger" href="#"><data m3ico="Delete icon-10"><span className="material-icons icon-10">delete_forever</span></data><label><data m3lab="ActionMenu.delete">Cancella</data></label></a></li>*/}
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="h5">
                                            <var>{templateData.product.name}</var>
                                        </div>

                                        {/* Best or selected option */}
                                        {isLoading
                                            ? (<div><div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div>{t(`Template:Loading`)}</div>)
                                            : (<>{renderSelectedOption()}</>)
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Alternative Options */}
                            {!isLoading && activityAvailability && showChangeOptions && (
                                <>
                                    <div className="m-0 p-2">
                                        {activityAvailability.groupedOptions && activityAvailability.groupedOptions.length > 0 && activityAvailability.groupedOptions.map((option, key) => {
                                            return <ActivityDetailOption
                                                key={key}
                                                option={option}
                                                showPriceBar={false}
                                                showCost={false}
                                                enableShowPriceBreakDown={false}
                                                enableCanAddToCart={{ allowed: true }}
                                                onBook={selectOption}
                                                templateInfo={{ templateId: templateData.templateId, selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null }}
                                            />
                                        })}
                                    </div>
                                </>
                            )}

                            {/* Button Remove when Optional */}
                            {!isLoading && templateData.inclusion === TemplateInclusion.Optional && (
                                <>
                                    <div>
                                        <div className="float-end small text-blue">
                                            <button id={`btnUnselect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonUnselect()}>
                                                <span className="text-danger">
                                                    <data m3lab="Template.Delete">Rimuovi</data>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    }

                    {
                        (isAlternative || isOptional) && !isLoading && !isError && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                                <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                    <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadTemplateStep()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}></div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                    <div className="w-100">
                                        <div className="h6 m-0">
                                            <var>{templateData.product.name}</var>
                                        </div>

                                        {!isLoading && renderSelectedOption()}
                                    </div>
                                </div>
                                {!isError &&
                                    <div className="col-3 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-right float-end pr-0 align-middle h-100">
                                        <button id={`btnSelect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonSelect()}>
                                            {isLoading && <><div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div>{t(`Template:LoadingAlternatives`)}</>}

                                            {!isLoading && activityAvailability && activityAvailability.groupedOptions && activityAvailability.groupedOptions.length > 0 &&
                                                <React.Fragment>
                                                    <data m3lab="Template.Option">{isAlternative ? t(`Template:Option`) : t(`Template:Add`)}</data>
                                                    <div>
                                                        {getTotalPriceDiff(activityAvailability.groupedOptions)}
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </React.Fragment>
            }
        </>
    );
}
