import ReactDOMServer from "react-dom/server";
import { generateComponentInBody, replaceAllStyleElements, dynamicNotesDistincts } from "../../../js/PrintUtility";
import { formatPrice } from '../../../js/Utils.js';
import { ConfirmPrintStepHeader } from "../../Documents/printConfirm/ConfirmPrintStepHeader";
import { ConfirmPrintStepItem } from "../../Documents/printConfirm/ConfirmPrintStepItem";
import { ConfirmTourStaticPrint } from "../../Documents/printConfirm/ConfirmTourStaticPrint";
import { ConfirmPrintPaxes } from "../../Documents/printConfirm/ConfirmPrintPaxes";
import { ConfirmPrintDynamicNotes } from "../../Documents/printConfirm/ConfirmPrintDynamicNotes";
import { ConfirmPrintTitle } from "../../Documents/printConfirm/ConfirmPrintTitle";
import { ConfirmPrintProductsNotes } from "../../Documents/printConfirm/ConfirmPrintProductsNotes";

// crea un array ordinato con i voli nei primi posti
function getFinalArr(itemStepPrint) {
    var tempArrFlights = [];
    var tempArrOthers = [];

    for (var i = 0; i < itemStepPrint.length; i++) {
        let qInfo = itemStepPrint[i];

        if (qInfo.productSubType === 'Flight') {
            tempArrFlights.push(qInfo);
        }
        else {
            tempArrOthers.push(qInfo);
        }
    }

    return tempArrFlights.concat(tempArrOthers);
}

/* verifica se le note superano il massimo numero di righe, circa 10 */
function checkIfNotesTooBig(noteArr) {
    let isTooBig = false;
    let numberChar = 0;
    
    if (noteArr.length > 0 && noteArr[0] == null)
        return isTooBig;

    noteArr.forEach(function (note) {
        if ((numberChar + note.length) > 800) {
            isTooBig = true;
        }
        else {
            numberChar += note.length;
        }
    });

    return isTooBig;
}

/* calcola se le note di un prodotto sono lunghe e le salvain un array, che poi verrà mostrato in fondo alla pagina */
function getProductsNotesArray(noteArr, name, roomName="") {
    let arrNotesProdcuts = [];

    arrNotesProdcuts.push({
        name: name + roomName,
        notes: noteArr,
    });

    return arrNotesProdcuts;
}

export function generateQuotationHTMLDocViaggio(orderNumber, startDate, itemStepPrint, quotationPaxes, dynamicNotes, dynamicNotesTop, dynamicNotesStep, confirmMarginItems, praticheSteps, template, scadenziarioPrint, notesPratica, cultureName, isPrintCliente=false) {
    let myArr = [];
    let isFirstFlight = true;
    let isFirstService = true;

    localStorage.removeItem("oldIdFoundImage");

    let dynamicNotesDistinct = dynamicNotesDistincts(dynamicNotes);

    let rowTitleQuotation = ReactDOMServer.renderToString(<ConfirmPrintTitle
        date={startDate}
        orderNumber={orderNumber }
        cultureName={cultureName}
    />);

    myArr.push({ html: generateComponentInBody(rowTitleQuotation), break: false, wrap: true });

    /* passeggeri */
    if (quotationPaxes && quotationPaxes.length > 0) {
        let rowPaxes = ReactDOMServer.renderToString(<ConfirmPrintPaxes
            paxes={quotationPaxes}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowPaxes), break: false, wrap: true });
    }

    if (dynamicNotesTop && dynamicNotesTop.length > 0) {
        let rowDynamicNotesTop = ReactDOMServer.renderToString(<ConfirmPrintDynamicNotes
            notes={dynamicNotesDistincts(dynamicNotesTop)}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotesTop), break: false, wrap: true });
    }
    
    var finalArr = getFinalArr(itemStepPrint);
    
    var arrNotesProdcuts = [];

    /* Elenco servizi */
    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];
        
        // crea intestazione/titolo tipo di servizio, lo crea una volta sola
        if (qInfo.productSubType === 'Flight' && isFirstFlight) {
            let rowStep = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
                title="I VOSTRI VOLI"
                PNR={qInfo.productInfo.priceBar.supplierPNR}
                totaleViaggio=''
                iconName='voli'
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowStep), break: false, wrap: true });

            isFirstFlight = false;
        }

        if (qInfo.productSubType === 'Flight') {
            /* Prodotti  */
            let rowQInfo = ReactDOMServer.renderToString(<ConfirmPrintStepItem
                qInfo={qInfo}
                isPrintCliente={isPrintCliente}
                isNotesHide={false}
                dynamicNotesDistinct={dynamicNotesDistinct}
            />);

            myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });
        }
    }

    var idArrNote = [];
    if (praticheSteps && praticheSteps.length > 0) {
        praticheSteps.forEach(function (step, stepKey) {
            itemStepPrint.filter(x => x.productInfo.stepId === step.step && x.productType !== "Flight" && x.productType !== "Generic").map((item, itemKey) => {
                let isNotesHide = false;
                let resProductArr = [];

                if ((item.productType === 'Activity') && item.productInfo.option.notes && item.productInfo.option.notes.length > 0) {
                    if (item.productInfo.option.notes)
                        isNotesHide = checkIfNotesTooBig(item.productInfo.option.notes);

                    if (isNotesHide) {
                        resProductArr = getProductsNotesArray(item.productInfo.option.notes, item.productInfo.name);
                        if (resProductArr.length > 0) {
                            arrNotesProdcuts.push(resProductArr);
                        }
                    }
                }
                else if ((item.productType === 'Tour' || item.productSubType === 'Hotel') && item.productInfo) {
                    if (item.productInfo.rooms && item.productInfo.rooms.length > 0) {
                        let finalRooms = item.productInfo.rooms.filter(x =>
                            x.priceBar.status === "CONFIRMED" ||
                            x.priceBar.status === "ONREQUEST" ||
                            x.priceBar.status === "PRINTED" ||
                            x.priceBar.status === "REFUND" ||
                            x.priceBar.status === "PENALTY"
                        );
                        /* controlla che tutte le note dell'hotel siano contenute nel massimo numero di righe */
                        finalRooms.forEach(function (room) {
                            if (room.notes.length > 0 && room.notes[0] != "")
                                isNotesHide = checkIfNotesTooBig(room.notes);
                        });

                        /* se le note eccedono il max, allora le inserisce nell'array */
                        if (isNotesHide) {
                            finalRooms.forEach(function (room) {
                                if (room.notes.length > 0 && room.notes[0] != "") {
                                    resProductArr = getProductsNotesArray(room.notes, item.productInfo.name, " (Camera " + room.sequence + ")");
                                    if (resProductArr.length > 0) {
                                        arrNotesProdcuts.push(resProductArr);
                                    }
                                }
                            });
                        }
                    }
                }
                else if (item.productSubType === 'Cruise') {
                    if (item.productInfo.note && item.productInfo.note != "")
                        isNotesHide = checkIfNotesTooBig([item.productInfo.note]);

                    /* se le note eccedono il max, allora le inserisce nell'array */
                    if (isNotesHide) {
                        if (item.productInfo.note != "") {
                            resProductArr = getProductsNotesArray([item.productInfo.note], item.productInfo.name);
                            if (resProductArr.length > 0) {
                                arrNotesProdcuts.push(resProductArr);
                            }
                        }
                    }
                }
                else if (item.productSubType === 'CarRental') {
                    if (item.productInfo.option.notes)
                        isNotesHide = checkIfNotesTooBig(item.productInfo.option.notes);

                    if (isNotesHide) {
                        resProductArr = getProductsNotesArray(item.productInfo.option.notes, item.productInfo.name);
                        if (resProductArr.length > 0) {
                            arrNotesProdcuts.push(resProductArr);
                        }
                    }
                }
                if (itemKey === 0) {
                    let rowStepheader = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
                        key={stepKey}
                        title={step.stepDestinationName}
                        iconName=''
                        stepIndex={step.step}
                        minDate={step.dateFrom}
                        maxDate={step.dateTo}
                    />);
                    myArr.push({ html: generateComponentInBody(rowStepheader), break: false, wrap: true });

                    if (dynamicNotesStep && dynamicNotesStep.length > 0) {
                        var showImage = true;
                        var hideText = false;

                        dynamicNotesStep.map((note, idx) => {
                            if (note.step === step.step) {
                                if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
                                    idArrNote.push({ id: note.noteId, step: note.step });
                                }
                                else {
                                    hideText = true;
                                }

                                let rowNoteStep = ReactDOMServer.renderToString(<ConfirmPrintDynamicNotes
                                    notes={[note]}
                                    cultureName={cultureName}
                                    isShowImage={showImage}
                                    hideText={hideText}
                                />);

                                myArr.push({ html: generateComponentInBody(rowNoteStep), break: false, wrap: true });

                                showImage = false;
                            }
                        });
                    }
                }

                let rowItem = ReactDOMServer.renderToString(<ConfirmPrintStepItem
                    key={step.step}
                    qInfo={item}
                    isPrintCliente={isPrintCliente}
                    isPrintPdf={false}
                    isNotStep={false}
                    isNotesHide={isNotesHide}
                    dynamicNotesDistinct={dynamicNotesDistincts(dynamicNotes)}
                />);

                myArr.push({ html: generateComponentInBody(rowItem), break: false, wrap: true });

            });
        })
    } 

    if (dynamicNotesDistinct && dynamicNotesDistinct.length > 0) {
        /* Note dinamiche */
        let rowNote = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
            title="INFO UTILI"
            PNR=''
            totaleViaggio=''
            iconName='info_utili'
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowNote), break: false, wrap: true });

        let rowDynamicNotes = ReactDOMServer.renderToString(<ConfirmPrintDynamicNotes
            notes={dynamicNotesDistinct}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
    }

    let schedeStatiche = [];
    let nameActivityTourArr = [];
    let nameStructureArr = [];
    let nameTourArr = [];

    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];
        /* Programma di viaggio, note extra */
        if (qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;

            if (nameActivityTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                nameActivityTourArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.option.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);

                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
        else if (qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;
            if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                nameStructureArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
        else if (qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;
            if (nameTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                nameTourArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }



        /* note pratica */
        if (notesPratica && notesPratica.length > 0) {
            notesPratica.forEach(function (note) {

                if (note.idPratica === qInfo.productInfo.idPratica) {
                    let notePratica = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                        titleStep={qInfo.productInfo.name}
                        descriptions={replaceAllStyleElements(note.nota)}
                        title={note.title}
                    />);

                    schedeStatiche.push({ html: generateComponentInBody(notePratica), break: false, wrap: true });
                }
            });
        }
    }

    //myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
    myArr.push(...schedeStatiche);

    /* Note prodotti (lunghe) */
    if (arrNotesProdcuts.length > 0) {
        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });

        let rowNoteProduct = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
            title="NOTE PRODOTTI"
            PNR=''
            totaleViaggio=''
            iconName=''
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowNoteProduct), break: false, wrap: true });


        var nameProduct = "";
        let rowPorductsNotes;
        arrNotesProdcuts.forEach(function (product) {
            for (var k = 0; k < product[0].notes.length; k++) {
                let note = replaceAllStyleElements(product[0].notes[k]);

                if (k > 0)
                    nameProduct = "";
                else
                    nameProduct = product[0].name;

                rowPorductsNotes = ReactDOMServer.renderToString(<ConfirmPrintProductsNotes
                    name={nameProduct}
                    note={note}
                />);

                myArr.push({ html: generateComponentInBody(rowPorductsNotes), break: false, wrap: true });
            }
        });
    }
    
    return myArr;
}