import React from 'react';
import { Login } from './Auth/Login';
import { Engine } from "./Engine";
import { useTranslation } from 'react-i18next';
import { getAuthToken } from '../js/Utils.js';

export const Home = () => {
    const { t } = useTranslation();

    let token = getAuthToken();

    let params = new URLSearchParams(window.location.search);
    let mySSOToken = params.get('ssoToken');
    let mySTkn = params.get('sTkn');
    let addToOrder = params.get('ato');
    let userAutoLogin = params.get('ual');

    return (
        <>
            <div>
                {
                    !token || (mySSOToken !== null || mySTkn !== null || addToOrder !== null || userAutoLogin !== null)
                        ? (<div className="container"><div className="pt-7"><h3 className="text-center">{t(`home:welcome`)}</h3><Login /></div></div>)
                        : (<div><Engine /></div>)
                }
            </div>
        </>
    );
}
