import React from 'react';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getIconUrlToPrintCustom } from '../../../js/PrintUtility';
import { BookParameterId } from '../../../js/Constant';

export const QuotationPrintPaxes = (props) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const bookParamQuotationPaxType = 'PAX_TYPE_QUOT';

    var paxesArrayVertical = [];
    var paxesArrayHorizontal = [];
    var refPax = null;
    var idx = 1;

    function getADTTotal(paxes) {
        let adt = 0;
        paxes.forEach((pax) => {
            if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'ADT')
                adt++;
        });

        return adt;
    }

    function getCHDTotal(paxes) {
        let chd = 0;
        paxes.forEach((pax) => {
            if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'CHD')
                chd++;
        });

        return chd;
    }

    function getINFTotal(paxes) {
        let inf = 0;
        paxes.forEach((pax) => {
            if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'INF')
                inf++;
        });

        return inf;
    }

    function setRefPax(paxes) {
        let pax = null;
        for (var i = 0; i < paxes.length; i++) {
            if (paxes[i].isRefPax) {
                pax = paxes[i];
                break;
            }
        }

        setOtherPaxes(paxes);
        refPax = pax;

        return pax;
    }

    function getRefPax() {
        return refPax;
    }

    function setOtherPaxes(paxes) {
        var idxPax = 2;

        paxes = paxes.slice(0, 30); // accorcia array ad un max di 30

        for (var i = 0; i < paxes.length; i++) {
            if (!paxes[i].isRefPax || (paxes[i].isRefPax && i > 0)) {
                if (idxPax <= 3) {
                    paxesArrayVertical.push(paxes[i]);
                }
                else {
                    paxesArrayHorizontal.push(paxes[i]);
                }
                idxPax++;
            }
        }
    }

    function getIndexArr() {
        idx = idx + 1;

        return idx;
    }

    function getArrayPaxesHorizontal() {
        let arrayTempPaxes = [];
        let arrayPaxes = [];

        for (var i = 0; i < paxesArrayHorizontal.length; i++) {
            arrayTempPaxes.push(paxesArrayHorizontal[i]);

            if (i === 1 || i === 3 || i === 5 || i === 7 || i === 9 || i === 11 || i === 13 || i === 15 || i === 17 || i === 19 || i === 21 || i === 23 || i === 25 || i === 27 || i === 29) {
                arrayPaxes.push(arrayTempPaxes);
                arrayTempPaxes = [];
            }
        }

        if (arrayTempPaxes.length > 0)
            arrayPaxes.push(arrayTempPaxes);

        return arrayPaxes;
    }

    // renderizza i pax per 2 a 2 verticalmente
    const renderPaxNoRefHorizontal = () => {
        let arrayPaxes = getArrayPaxesHorizontal();

        return (
            <>
                {arrayPaxes.length > 0 &&
                    arrayPaxes.map((arrPass, key) =>
                        <tr style={{ borderRight: '1px dotted grey', borderLeft: '1px dotted grey', borderBottom: '1px dotted grey', paddingTop: '8px', paddingBottom: '8px' }} >
                            {arrPass.map((pax, index) =>
                                <td className="col-lg-6">
                                    <tr>
                                        <td>
                                            {renderPaxNoRef(pax)}
                                        </td>
                                        {index < 1 &&
                                            <td style={{ width: '10%', maxWidth: '10%' }}>
                                                <img src={getIconUrlToPrintCustom('puntini_oro_verticali')} className="image-puntini-verticali" style={{top: '10%'}} />
                                            </td>
                                        }
                                    </tr>
                                </td>
                            )}
                        </tr>
                    )}
            </>
        );
    }

    // renderizza i pax per 2 a 2 orizzontalmente
    const renderPaxNoRefVertical = () => {
        return (
            <>
                {paxesArrayVertical.length > 0 &&
                    <td style={{marginBottom: '5px',}}>
                        {paxesArrayVertical.map((pax, index) => 
                            <>
                                {renderPaxNoRef(pax)}
                                {index < 1 &&
                                    <tr>
                                        <td> 
                                            <img src={getIconUrlToPrintCustom('puntini_oro_orizzontali')} className="image-puntini-orizzontali" style={{left: '30%'}} />
                                        </td>
                                    </tr>
                                }
                            </>
                        )}
                    </td>
                }
            </>
        );
    }

    const renderPaxNoRef = (pax) => {
        return (
            <>
                <tr>
                    <td style={{ textAlign: 'left' }}>
                        <h5>PASSEGGERO {getIndexArr()}</h5>
                    </td>
                </tr>
                <tr>
                    <td className="col-lg-4" style={{ textAlign: 'left', marginTop: '-3px' }}>
                        <h6>Nome/Cognome</h6>
                    </td>
                    <td style={{ textAlign: 'left', marginTop: '-3px' }}>
                        <h6>
                            {pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0].bpValue + ' '}
                            {pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.MiddleName)[0] &&
                                pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.MiddleName)[0].bpValue + ' '}
                            {pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.LastName)[0].bpValue}
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left', marginTop: '-3px' }}>
                        <h6>Data di nascita</h6>
                    </td>
                    <td style={{ textAlign: 'left', marginTop: '-3px' }}>
                        <h6>{pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].bpValue != "" &&
                            formatDateTime(pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].bpValue, cultureName)}</h6>
                    </td>
                    <td className="col-lg-2" style={{ textAlign: 'left', marginTop: '-3px' }}>
                        <h6>Genere</h6>
                    </td>
                    <td style={{ textAlign: 'left', marginTop: '-3px' }}>
                        <h6>{pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0] &&
                            pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0].bpValue}</h6>
                    </td>
                </tr>
            </>
        );
    }
  
    return (
        <>
            {props.paxes.length > 0 ? <>
                <div>
                    <table>
                        <tr>
                            <td style={{ width: '15%', maxWidth: '15%', textAlign: 'left' }}>
                                <h5>Passeggeri: </h5>
                            </td>
                            <td style={{ width: '4%', maxWidth: '4%', textAlign: 'left' }}>
                                <img src={getIconUrlToPrintCustom('uomo')} className="ico-image-large" style={{ left: '5px' }} />
                            </td>
                            <td style={{ width: '4%', maxWidth: '4%', textAlign: 'left' }}>
                                <h5>{getADTTotal(props.paxes)}</h5>
                            </td>
                            <td style={{ width: '4%', maxWidth: '4%', textAlign: 'left' }}>
                                <img src={getIconUrlToPrintCustom('bambino')} className="ico-image-large" style={{ left: '5px' }} />
                            </td>
                            <td style={{ width: '4%', maxWidth: '4%', textAlign: 'left' }}>
                                <h5>{getCHDTotal(props.paxes)}</h5>
                            </td>
                            <td style={{ width: '4%', maxWidth: '4%', textAlign: 'left' }}>
                                <img src={getIconUrlToPrintCustom('neonato')} className="ico-image-large" style={{ left: '5px' }} />
                            </td>
                            <td style={{ width: '4%', maxWidth: '4%', textAlign: 'left' }}>
                                <h5>{getINFTotal(props.paxes)}</h5>
                            </td>
                        </tr>
                    </table>
                        {
                        setRefPax(props.paxes) != null &&
                        <>
                            <table>
                                <tr>
                                    <td className="col-lg-6" style={{ borderTop: '6px solid #DAB451', borderRight: '6px solid #DAB451',  }}>
                                        <tr>
                                            <td style={{ textAlign: 'left', fontfamily: 'Helvetica-Bold' }}>
                                                <h5>PASSEGGERO REFERENTE</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4" style={{ textAlign: 'left' }}>
                                                <h6>Nome/Cognome</h6>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>
                                                    {getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0].bpValue + ' '}
                                                    {getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.MiddleName)[0] &&
                                                        getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.MiddleName)[0].bpValue + ' '}
                                                    {getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.LastName)[0].bpValue}
                                                </h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>Data di nascita</h6>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>{getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0] &&
                                                     formatDateTime(getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].bpValue, cultureName)}</h6>
                                            </td>
                                            <td className="col-lg-2" style={{ textAlign: 'left' }}>
                                                <h6>Genere</h6>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>{getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0] &&
                                                    getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0].bpValue}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-2" style={{ textAlign: 'left' }}>
                                                <h6>C.F.</h6>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>{getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0] &&
                                                    getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0].bpValue}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-2" style={{ textAlign: 'left' }}>
                                                <h6>Tel.</h6>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>{getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Contact.HomePhone)[0] &&
                                                    getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Contact.HomePhone)[0].bpValue}</h6>
                                            </td>
                                            <td className="col-lg-2" style={{ textAlign: 'left' }}>
                                                <h6>Cell.</h6>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>{getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Contact.MobilePhone)[0] &&
                                                    getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Contact.MobilePhone)[0].bpValue}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-2" style={{ textAlign: 'left' }}>
                                                <h6>Mail</h6>
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                                <h6>{getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Contact.Email)[0] &&
                                                    getRefPax().bookParams.filter(x => x.bpCode === BookParameterId.Contact.Email)[0].bpValue}</h6>
                                            </td>
                                        </tr>
                                    </td>
                                    {renderPaxNoRefVertical()}
                                </tr>
                                {renderPaxNoRefHorizontal()}
                            </table>
                        </>
                    }
                </div>
                <table>
                    <tr>
                        <td></td>
                        <td className="col-lg-2" style={{ borderBottom: '2px solid #D9B451', marginTop: '10px', marginBottom: '10px' }}></td>
                        <td></td>
                    </tr>
                </table>
            </>
            : <></>
            }
        </>
    );
}