import ReactDOMServer from "react-dom/server";
import { generateComponentInBody, replaceAllStyleElements, dynamicNotesDistincts } from "../../../js/PrintUtility";
import { formatPrice } from '../../../js/Utils.js';
import { QuotationPrintStepHeader } from "../../QuotationTO/print/QuotationPrintStepHeader";
import { QuotationPrintStepItem } from "../../QuotationTO/print/QuotationPrintStepItem";
import { QuotationTourStaticPrint } from "../../QuotationTO/print/QuotationTourStaticPrint";
import { QuotationPrintDiscount } from "../../QuotationTO/print/QuotationPrintDiscount";
import { QuotationPrintAncillaries } from "../../QuotationTO/print/QuotationPrintAncillaries";
import { QuotationPrintCommissionsDetail } from "../../QuotationTO/print/QuotationPrintCommissionsDetail";
import { QuotationPrintPaxes } from "../../QuotationTO/print/QuotationPrintPaxes";
import { QuotationPrintDynamicNotes } from "../../QuotationTO/print/QuotationPrintDynamicNotes";
import { QuotationPrintTitleQuotation } from "../../QuotationTO/print/QuotationPrintTitleQuotation";
import { QuotationPrintProductsNotes } from "../../QuotationTO/print/QuotationPrintProductsNotes";
import { QuotationPrintScadenziario } from "../../QuotationTO/print/QuotationPrintScadenziario";
import { QuotationPrintCancelPolicy } from "../../QuotationTO/print/QuotationPrintCancelPolicy";

// crea un array ordinato con i voli nei primi posti
function getFinalArr(quotationItemStepPrint) {
    var tempArrFlights = [];
    var tempArrOthers = [];

    for (var i = 0; i < quotationItemStepPrint.length; i++) {
        let itemStep = quotationItemStepPrint[i];

        for (var j = 0; j < itemStep.quotationItemInfo.length; j++) {
            let qInfo = itemStep.quotationItemInfo[j];
            if (qInfo.productSubType === 'Flight') {
                tempArrFlights.push(qInfo);
            }
            else {
                tempArrOthers.push(qInfo);
            }
        }
    }

    return tempArrFlights.concat(tempArrOthers);
}

/* verifica se le note superano il massimo numero di righe, circa 10 */
function checkIfNotesTooBig(noteArr) {
    let isTooBig = false;
    let numberChar = 0;

    noteArr.forEach(function (note) {
        if ((numberChar + note.length) > 800) {
            isTooBig = true;
        }
        else {
            numberChar += note.length;
        }
    });

    return isTooBig;
}

/* calcola se le note di un prodotto sono lunghe e le salvain un array, che poi verrà mostrato in fondo alla pagina */
function getProductsNotesArray(noteArr, name, roomName = "") {
    let arrNotesProdcuts = [];

    arrNotesProdcuts.push({
        name: name + roomName,
        notes: noteArr,
    });

    return arrNotesProdcuts;
}

export function generateQuotationHTMLFromAgency(quotationHeader, quotationId, quotationTableMarginItems, quotationRecapItems, quotationItemStepPrint, customerInfoQuotation, quotation, quotationPaxes, dynamicNotes, dynamicNotesTop, dynamicNotesStep, scadenziario, cancelPolicyArr, cultureName, isPrintCliente = false,
    isPrintCommissions,
    isPrintScadenziario,
    isPrintCancelPolicy,
    isPrintDynamicNotes,
    isShowStep
) {
    let myArr = [];
    let isFirstFlight = true;
    let isFirstService = true;
    localStorage.removeItem("oldIdFoundImage");

    let dynamicNotesDistinct = dynamicNotesDistincts(dynamicNotes);

    let rowTitleQuotation = ReactDOMServer.renderToString(<QuotationPrintTitleQuotation
        title={quotationHeader.name}
        date={quotationHeader.createDate}
        quotationId={quotationId}
        cultureName={cultureName}
    />);

    myArr.push({ html: generateComponentInBody(rowTitleQuotation), break: false, wrap: true });

    /* passeggeri */
    if (quotationPaxes && quotationPaxes.length > 0) {
        let rowPaxes = ReactDOMServer.renderToString(<QuotationPrintPaxes
            paxes={quotationPaxes}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowPaxes), break: false, wrap: true });
    }

    if (dynamicNotesTop && dynamicNotesTop.length > 0) {
        let rowDynamicNotesTop = ReactDOMServer.renderToString(<QuotationPrintDynamicNotes
            notes={dynamicNotesDistincts(dynamicNotesTop)}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotesTop), break: false, wrap: true });
    }

    var finalArr = quotationItemStepPrint;

    let isNotesHide = false;
    var arrNotesProdcuts = [];

    /* Elenco servizi */
    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];

        if (qInfo.isAlternative && !qInfo.isSelected)
            continue;

        // crea intestazione/titolo tipo di servizio, lo crea una volta sola
        if (qInfo.productSubType === 'Flight' && isFirstFlight) {
            let rowStep = ReactDOMServer.renderToString(<QuotationPrintStepHeader
                title="I VOSTRI VOLI"
                PNR={qInfo.productInfo.priceBar.supplierPNR}
                totaleViaggio=''
                iconName='voli'
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowStep), break: false, wrap: true });

            isFirstFlight = false;
        }
        else if (isFirstService && qInfo.productSubType !== 'Flight') {
            let rowStep = ReactDOMServer.renderToString(<QuotationPrintStepHeader
                title="ELENCO SERVIZI"
                PNR=''
                totaleViaggio=''
                iconName='elenco_servizi'
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowStep), break: true, wrap: true });

            isFirstService = false;
        }

        if (qInfo.productSubType === 'Flight') {
            let rowQInfo = ReactDOMServer.renderToString(<QuotationPrintStepItem
                qInfo={qInfo}
                isPrintCliente={isPrintCliente}
                isNotesHide={isNotesHide}
                dynamicNotesDistinct={dynamicNotesDistinct}
            />);

            myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });
        }
    }

    var idArrNote = [];
    if (isShowStep && quotation && quotation.quotationItemStep && quotation.quotationItemStep.length > 0) {
        quotation.quotationItemStep.map((step, stepKey) => {
            if (step.quotationItemInfo && step.quotationItemInfo.length > 0) {
                step.quotationItemInfo.filter(x => x.productType !== "Flight" && x.productType !== "Generic").map((item, itemKey) => {
                    let isNotesHide = false;
                    let resProductArr = [];

                    let qInfo = finalArr.find(c => c.quotationItemId === item.id);
                    if (qInfo) {
                        if (qInfo.productType === 'Activity' && qInfo.productInfo && qInfo.productInfo.option && qInfo.productInfo.option.notes && qInfo.productInfo.option.notes.length > 0) {
                            if (qInfo.productInfo.option.notes)
                                isNotesHide = checkIfNotesTooBig(qInfo.productInfo.option.notes);

                            if (isNotesHide) {
                                resProductArr = getProductsNotesArray(qInfo.productInfo.option.notes, qInfo.productInfo.name);
                                if (resProductArr.length > 0) {
                                    arrNotesProdcuts.push(resProductArr);
                                }
                            }
                        }
                        else if ((qInfo.productType === 'Tour' || qInfo.productSubType === 'Hotel') && qInfo.productInfo) {
                            /* controlla che tutte le note dell'hotel siano contenute nel massimo numero di righe */
                            qInfo.productInfo.rooms.forEach(function (room) {
                                if (room.notes.length > 0 && room.notes[0] != "")
                                    isNotesHide = checkIfNotesTooBig(room.notes);
                            });

                            /* se le note eccedono il max, allora le inserisce nell'array */
                            if (isNotesHide) {
                                qInfo.productInfo.rooms.forEach(function (room) {
                                    if (room.notes.length > 0 && room.notes[0] != "") {
                                        resProductArr = getProductsNotesArray(room.notes, qInfo.productInfo.name, " (Camera " + room.sequence + ")");
                                        if (resProductArr.length > 0) {
                                            arrNotesProdcuts.push(resProductArr);
                                        }
                                    }
                                });
                            }
                        }
                        else if (qInfo.productSubType === 'Cruise') {
                            if (qInfo.productInfo.note && qInfo.productInfo.note != "")
                                isNotesHide = checkIfNotesTooBig([qInfo.productInfo.note]);

                            /* se le note eccedono il max, allora le inserisce nell'array */
                            if (isNotesHide) {
                                if (qInfo.productInfo.note != "") {
                                    resProductArr = getProductsNotesArray([qInfo.productInfo.note], qInfo.productInfo.name);
                                    if (resProductArr.length > 0) {
                                        arrNotesProdcuts.push(resProductArr);
                                    }
                                }
                            }
                        }
                        else if (qInfo.productSubType === 'CarRental') {
                            if (qInfo.productInfo.option.notes)
                                isNotesHide = checkIfNotesTooBig(qInfo.productInfo.option.notes);

                            if (isNotesHide) {
                                resProductArr = getProductsNotesArray(qInfo.productInfo.option.notes, qInfo.productInfo.name);
                                if (resProductArr.length > 0) {
                                    arrNotesProdcuts.push(resProductArr);
                                }
                            }
                        }

                        if (itemKey === 0) {
                            let rowStepheader = ReactDOMServer.renderToString(<QuotationPrintStepHeader
                                key={stepKey}
                                destination={step.stepDestinationName}
                                iconName=''
                                stepIndex={step.step}
                                minDate={step.dateFrom}
                                maxDate={step.dateTo}
                            />);
                            myArr.push({ html: generateComponentInBody(rowStepheader), break: false, wrap: true });

                            if (dynamicNotesStep && dynamicNotesStep.length > 0) {
                                var showImage = true;
                                var hideText = false;

                                dynamicNotesStep.map((note, idx) => {
                                    if (note.step === step.step) {
                                        if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
                                            idArrNote.push({ id: note.noteId, step: note.step });
                                        }
                                        else {
                                            hideText = true;
                                        }

                                        let rowNoteStep = ReactDOMServer.renderToString(<QuotationPrintDynamicNotes
                                            notes={[note]}
                                            cultureName={cultureName}
                                            isShowImage={showImage}
                                            hideText={hideText}
                                        />);

                                        myArr.push({ html: generateComponentInBody(rowNoteStep), break: false, wrap: true });

                                        showImage = false;
                                    }
                                });
                            }
                        }

                        let rowItem = ReactDOMServer.renderToString(<QuotationPrintStepItem
                            key={itemKey}
                            qInfo={finalArr.find(c => c.quotationItemId === item.id)}
                            isPrintCliente={isPrintCliente}
                            isTemplate={true}
                            isConfirmPrint={false}
                            isPrintPdf={false}
                            isNotStep={false}
                            dynamicNotesDistinct={[]}
                            isNotesHide={isNotesHide}
                        />);

                        myArr.push({ html: generateComponentInBody(rowItem), break: false, wrap: true });
                    }
                });
            }
        });
    }


    /* Sconto commerciale */
    let rowDiscount = ReactDOMServer.renderToString(<QuotationPrintDiscount
        recapItems={quotationRecapItems}
        cultureName={cultureName}
    />);

    myArr.push({ html: generateComponentInBody(rowDiscount), break: false, wrap: true });

    /* Quote accessorie */
    let rowAncillaries = ReactDOMServer.renderToString(<QuotationPrintAncillaries
        recapItems={quotationRecapItems}
        cultureName={cultureName}
        isPrintCliente={isPrintCliente}
    />);

    myArr.push({ html: generateComponentInBody(rowAncillaries), break: false, wrap: true });

    /* Totale viaggio - dettaglio commissioni */
    function getTotaleViaggio(notCommissionableItems, commissionableItems) {
        let total = 0;
        let currency = '';

        notCommissionableItems.forEach(function (item) {
            currency = item.grossPriceCurrency;
            total = total + item.grossPriceAmount;
        });

        commissionableItems.forEach(function (item) {
            currency = item.grossPriceCurrency;
            if (item.type === 'TotaleAgencyFee' || item.type === 'TotaleAgencyMarkup') {
                total = total + item.totalCommissioneAmount;
            } else {
                total = total + item.grossPriceAmount;
            }
        });

        return formatPrice(total, currency, cultureName);
    }

    if (isPrintCommissions) {
        let rowStepViaggio = ReactDOMServer.renderToString(<QuotationPrintStepHeader
            title={(isPrintCliente ? "TOTALE VIAGGIO" : "TOTALE VIAGGIO - DETTAGLIO COMMISSIONI")}
            PNR=''
            totaleViaggio={isPrintCliente ? getTotaleViaggio(quotationTableMarginItems.tableMargin.notCommissionableItems, quotationTableMarginItems.tableMargin.commissionableItems) : ''}
            iconName='dettaglio_commissioni'
            cultureName={cultureName}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowStepViaggio), break: false, wrap: true });

        let rowCommDetail = ReactDOMServer.renderToString(<QuotationPrintCommissionsDetail
            marginItems={quotationTableMarginItems}
            totaleViaggio={getTotaleViaggio(quotationTableMarginItems.tableMargin.notCommissionableItems, quotationTableMarginItems.tableMargin.commissionableItems)}
            cultureName={cultureName}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowCommDetail), break: false, wrap: true });
    }

    if (!isPrintCliente && scadenziario && scadenziario.length > 0 && isPrintScadenziario) {
        /* Sacadenzirio */
        let rowScadenziarioTitle = ReactDOMServer.renderToString(<QuotationPrintStepHeader
            title="SCADENZE DI PAGAMENTO"
            PNR=''
            totaleViaggio=''
            iconName='cronologia'
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziarioTitle), break: false, wrap: true });

        let rowScadenziario = ReactDOMServer.renderToString(<QuotationPrintScadenziario
            scadenziario={scadenziario}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziario), break: false, wrap: true });
    }


    if (!isPrintCliente && isPrintCancelPolicy) {
        /* Cancel Policy */
        let rowCancelPolicyTitle = ReactDOMServer.renderToString(<QuotationPrintStepHeader
            title="DETTAGLIO PENALI PRODOTTO"
            PNR=''
            totaleViaggio=''
            iconName='elenco_servizi'
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicyTitle), break: false, wrap: true });

        let rowCancelPolicy = ReactDOMServer.renderToString(<QuotationPrintCancelPolicy
            cancelPolicyArr={cancelPolicyArr}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicy), break: false, wrap: true });
    }

    if (dynamicNotesDistinct && dynamicNotesDistinct.length > 0 && isPrintDynamicNotes) {
        /* Note dinamiche */
        let rowNote = ReactDOMServer.renderToString(<QuotationPrintStepHeader
            title="INFO UTILI"
            PNR=''
            totaleViaggio=''
            iconName='info_utili'
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowNote), break: false, wrap: true });

        let rowDynamicNotes = ReactDOMServer.renderToString(<QuotationPrintDynamicNotes
            notes={dynamicNotesDistinct}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
    }

    let schedeStatiche = [];
    let nameActivityTourArr = [];
    let nameStructureArr = [];
    let nameTourArr = [];

    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];

        if (qInfo.isAlternative && !qInfo.isSelected)
            continue;

        /* Programma di viaggio, note extra */
        if (qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;

            if (nameActivityTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                nameActivityTourArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.option.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<QuotationTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
        else if (qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;
            if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                nameStructureArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<QuotationTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
        else if (qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;
            if (nameTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                nameTourArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<QuotationTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
    }

    //myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
    myArr.push(...schedeStatiche);

    /* Note prodotti (lunghe) */
    if (arrNotesProdcuts.length > 0) {
        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });

        let rowNoteProduct = ReactDOMServer.renderToString(<QuotationPrintStepHeader
            title="NOTE PRODOTTI"
            PNR=''
            totaleViaggio=''
            iconName=''
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowNoteProduct), break: false, wrap: true });


        var nameProduct = "";
        let rowPorductsNotes;
        arrNotesProdcuts.forEach(function (product) {
            for (var k = 0; k < product[0].notes.length; k++) {
                let note = replaceAllStyleElements(product[0].notes[k]);

                if (k > 0)
                    nameProduct = "";
                else
                    nameProduct = product[0].name;

                rowPorductsNotes = ReactDOMServer.renderToString(<QuotationPrintProductsNotes
                    name={nameProduct}
                    note={note}
                />);

                myArr.push({ html: generateComponentInBody(rowPorductsNotes), break: false, wrap: true });
            }
        });
    }

    return myArr;
}