import React, { useEffect, useState } from 'react';
import configData from "../../appsettings.json";
import { handleError, GetModulesRules } from "../../js/Utils";
import { WebMenuVoices } from "./components/WebMenuVoices";
import Cookies from 'universal-cookie';

export const Footer = ({websiteId }) => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [myFooter, setMyFooter] = useState(null);
    const [MyKeyFooter, setMyKeyFooter] = useState(null);
    const [rules, setRules] = useState(null);

    useEffect(() => {
        if (cultureCode) {
            setMyKeyFooter("FooterItems_" + cultureCode + "_" + websiteId);
        }
        else {
            setMyKeyFooter("FooterItems_it-IT" + "_" + websiteId);
        }

    }, [cultureCode, websiteId]);

    useEffect(() => {
        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };
        callGetModuleRules();

        if (MyKeyFooter) {
            getFooter();
        }
    }, [MyKeyFooter]);

    function getFooter() {
        let strMyFooter = sessionStorage.getItem(MyKeyFooter);
        if (!strMyFooter) {

            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            let cc = cultureCode;
            if (!cc)
                cc = 'it-IT';

            fetch(`${configData.Settings.CommonApi_BaseUrl}website/getwebsitemenu/1/${websiteId}/${cc}`, requestOption)
                .then((res) => handleError(res))
                .then((item) => {
                    const footerItems = { items: item };
                    setMyFooter(footerItems);
                    sessionStorage.setItem(MyKeyFooter, JSON.stringify(footerItems));
                })
                .catch((err) => {
                    console.error(err);
                });

        } else {
            let jCurrentFooter = JSON.parse(strMyFooter);
            setMyFooter(jCurrentFooter);
        }

    }


    return (
        <footer id="footer" className="footer text-white position-relative bottom-0 w-100">
            <div className="container">
                {
                    myFooter && myFooter.items.map((item, key) => {
                        return <WebMenuVoices key={key} item={item} rules={rules} />
                    })
                }
            </div>
        </footer>
    );
}