export const printCssStyle = {
    html: {
        width: '100%',
    },

    page: {
        paddingBottom: '9%',
    },

    bodyPdf: {
        paddingLeft: '15px',
        paddingRight: '15px'
    },

    footer: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        textAlign: 'center',
    },

    pageNumber: {
        textAlign: 'right',
        fontSize: '10px',
        width: '99%',
        marginBottom: '5px'
    },

    div: {
        margin: 0,
        textAlign: 'center',
    },

    label: {
        fontSize: '7px',
        whiteSpace: 'normal',
    },

    span: {
        fontSize: '10px',
    },

    p: {
        margin: '1px',
        padding: '1px',
        fontSize: '10px',
    },

    h2: {
        fontWeight: 'bold',
        fontSize: '20px',
    },

    h3: {
        fontWeight: 'bold',
        fontSize: '17px',
    },

    h4: {
        fontWeight: 'bold',
        fontSize: '15px',
        margin: '0px',
    },

    h5: {
        fontWeight: 'bold',
        fontSize: '12px',
        margin: '0px',
    },

    h6: {
        fontWeight: 'bold',
        margin: '0px',
        padding: '0px',
        fontSize: '9px',
    },

    body: {
        fontFamily: 'Helvetica',
    },

    td: {
        padding: '4px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },

    img: { width: 100, height: 50 },

    ['.h5']: {
        fontSize: '12px',
        whiteSpace: 'normal',
    },

    ['.h6']: {
        fontSize: '9px',
        whiteSpace: 'normal',
    },

    ['.border']: {
        border: '1px solid #e7e7e7',
    },

    ['.col-lg-2']: {
        width: '15%',
        maxWidth: '15%',
    },

    ['.col-lg-3']: {
        width: '22%',
        maxWidth: '22%',
    },

    ['.col-lg-4']: {
        width: '33%',
        maxWidth: '33%',
    },

    ['.col-lg-5']: {
        width: '40%',
        maxWidth: '40%',
    },

    ['.col-lg-6']: {
        width: '50%',
        maxWidth: '50%',
    },

    ['.col-lg-7']: {
        width: '70%',
        maxWidth: '70%',
    },

    ['.col-lg-8']: {
        width: '90%',
        maxWidth: '90%',
    },

    ['.col-lg-12']: {
        width: '100%',
        maxWidth: '100%',
    },

    ['.mybook-order-main']: {
        borderRadius: '5px'
    },

    /* color */

    ['.text-success']: {
        color: '#198754',
    },

    ['.badge-gray-200']: {
        backgroundColor: '#fafbfe',
    },

    ['.badge-white']: {
        backgroundColor: '#FFFFFF',
    },

    ['.badge-customer']: {
        backgroundColor: '#08123B',
    },

    ['.badge-light-customer']: {
        backgroundColor: '#E8EAF1',
    },

    ['.bg-gradient-gray-400, .badge-light']: {
        backgroundColor: '#edf0f8',
        borderRadius: '5px'
    },

    ['.text-darkgrey']: {
        color: '#383838',
    },

    ['.text-white']: {
        color: '#FFFFFF',
    },

    ['.stepTag']: {
        borderRadius: '5px',
        padding: '2px',
        backgroundColor: '#FFFFFF'
    },

    ['.onrequest']: {
        backgroundColor: '#f2d710'
    },

    ['.p-3']: {
        padding: '16px',
    },

    ['.penalty']: {
        color: 'red',
    },

    ['.free']: {
        color: 'green',
    },

    ['.badge-customer-yellow']: {
        backgroundColor: '#DAB451',
    },

    

    ['.pagebreak']: {
        clear: 'both',
        pageBreakBefore: 'always',
    },

    ['.confirm']: {
        backgroundColor: '#BCD9D0',
    },

    ['.header']: {
        height: '500px',
    },

  
    ['.logo-header']: {
        height: '50px',
        width: '155px'
    },

    ['.logo-header-agency']: {
        width: '140px',
    },

    ['.logo-print']: {
        height: '20px',
        width: '65px'
    },

    ['.ico-image']: {
        height: '11px',
        width: '11px'
    },

    ['.ico-image-small']: {
        height: '7px',
        width: '7px'
    },

    ['.ico-image-large']: {
        height: '13px',
        width: '13px'
    },

    ['.ico-image-extra-large']: {
        paddingTop: '2px',
        height: '45px',
        width: '55px'
    },

    ['.image-puntini-verticali']: {
        width: '7px'
    },

    ['.image-puntini-orizzontali']: {
        height: '6px',
        width: '60px'
    },
    
    ['.imgFlight']: {
        height: '20px',
        width: '20px'
    },

    ['.card-img']: {
        height: '45px',
        width: '65px',
    },

    ['.img-note']: {
        height: '130px',
        width: '550px',
        marginTop: "-17px",
    },

    ['.card']: {
        borderRadius: '5px',
    },

    ['.borderThead']: {
        borderLeft: '1px',
    },

    ['.borderTBody']: {
        borderLeft: '1px dotted grey',
    },

    /* voucher */
    ['.badge-voucher']: {
        backgroundColor: '#b7c3d2',
        padding: '8px',
    },

    /* copertina */
    ['.copertina-img']: {
        width: '100%',
        height: '100%',
    },

    ['.color-text-voucher']: {
        color: "white"
    },

    ['.stepHeader']: {
        backgroundColor: '#08123B'
    }
};
