import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { M3Pax } from "../../Common/M3Pax";
import { TourRoom } from '../../Product/Tour/components/TourRoom';
import { getSearchParameterDate, getPassengerComposition, getCarpetStatus, TemplateInclusion, canStartSearch } from '../TemplateUtils';
import { generateClientFlowId } from '../../../js/ProductService.js';
import { imageOnLoadTemplateStep } from '../../../js/CDNUtility';
import { scrollTo, formatDateTime, formatPrice } from '../../../js/Utils.js';
import { searchCarpetParameter } from '../../../js/Constant';
import configData from "../../../appsettings.json";

export const TourTemplate = ({ templateData, products, selectedProducts, selectedProductData, searchParameters, isAlternative, isOptional, onSelectAlternative, onUpdateSelectedInfoData, onUnselectOptional, onFinishSearch }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    const [avlCarpetId, setAvlCarpetId] = useState(null);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState(null);
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);

    const [tourAvailability, setTourAvailability] = useState(null);
    const [showChangeRooms, setShowChangeRooms] = useState(false);
    const [availRoomsReq, setAvailRoomsReq] = useState([]);


    useEffect(() => {
        if (!avlCarpetId)
            return;

        callGetNewData(); // ping carpet to get results
    }, [avlCarpetId]);

    useEffect(() => {
        switch (carpetStatus) {
            case 'Error':
                setIsError(true);
                break;

            default: // 'Processing', 'Queued', 'Completed'
                break;
        }
    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                callGetNewData();
                clearCarpetInterval();
                getTourAvailability();
                break;

            case 'NewData':
                callGetNewData();
                clearCarpetInterval();
                //gettourAvailability();
                break;

            case 'NoChanges':
            case 'Processing':
                setCarpetInterval();
                break;

            default:
                break;
        }
    }, [carpetDataStatus]);

    useEffect(() => {
        let carpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.CarpetId : selectedProductData.AlternativeInfo.CarpetId
            : avlCarpetId;

        if (carpetId) {
            setIsLoading(true);
            setShowChangeRooms(false);
            getTourAvailability();
        }
    }, [templateData]);

    useEffect(() => {
        if (searchStarted || !canStartSearch(templateData.templateDataId, products, selectedProducts))
            return;

        setIsLoading(true);
        setSearchStarted(true);
        createCarpetAvailability();
        onFinishSearch(templateData.templateDataId, true);
    }, [selectedProducts]);


    // carpet functions
    async function createCarpetAvailability() {

        let inputData = {
            carpetOperation: "AVL",
            clientFlowId: generateClientFlowId(),
            //userId: "",
            sessionToken: cookies.get("SessionToken"),
            websiteId: configData.Settings.WebsiteId,
            productSubType: 12, // tour
            tourSearchParameters: {
                destinationWebIds:[],
                destinationDesc: [],
                dates: [],
                suppliersToExclude: [],
                suppliersToInclude: [],
                structureTypes: [],
                refundableOnly: false,
                availableOnly: false,
                testRequest: false,

                tourId: templateData.codiceProdotto,
                roomsComposition: getPassengerComposition(searchParameters, configData.Settings.Products.Tour.IdTipoProdotto),
                citizenship: searchParameters.citizenship,
                templateId: templateData.templateId,

                selectDate: getSearchParameterDate(searchParameters, templateData, configData.Settings.Products.Tour.IdTipoProdotto, "CHECKIN")
            }
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Tour/CreateCarpet', requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            setIsError(false);
            setAvlCarpetId(response.carpetId);
            onUpdateSelectedInfoData(templateData.templateDataId, response.carpetId, null, null, null, false, false);
        } else {
            setIsError(true);
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, true, true);
        }
    }
    async function callGetNewData() {
        if (carpetStatus === 'Completed')
            return;

        // conto quante chiamate sto facendo (ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato)
        let callTicks = carpetTime * searchCarpetParameter.tour.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.tour.carpetMaxTimeMs
        setCarpetTime(carpetTime + searchCarpetParameter.tour.carpetPingMs);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setCarpetInterval();
        } else {
            callGetCarpetStatus(avlCarpetId);
            clearCarpetInterval();
        }
    }
    async function callGetCarpetStatus() {
        let response = await getCarpetStatus(avlCarpetId);
        //console.log("CarpetStatus Response: " + JSON.stringify(response));

        if (!response.success) {
            setIsError(true);
        } else {
            let dataStatus = response.dataStatus;
            let status = response.status;
            setCarpetStatus(status);

            if (status !== 'Error') {
                // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges (lo status Processing esiste solo lato FE)
                setCarpetDataStatus(status !== carpetDataStatus ? dataStatus : 'Processing');
            }
        }
    }
    function setCarpetInterval() {
        if (!intervalCarpetPing) {
            //console.log("--- setCarpetInterval");
            let interval = setInterval(() => { callGetCarpetStatus(avlCarpetId); }, searchCarpetParameter.tour.carpetPingMs);
            setIntervalCarpetPing(interval);
        }
    }
    function clearCarpetInterval() {
        if (intervalCarpetPing) {
            //console.log("--- clearCarpetInterval");
            clearInterval(intervalCarpetPing);
            setIntervalCarpetPing(null);
        }
    }

    // availability functions
    async function getTourAvailability(selectedRooms) {
        let carpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.CarpetId : selectedProductData.AlternativeInfo.CarpetId
            : avlCarpetId;

        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            tourId: templateData.codiceProdotto,
            retrieveStaticData: true,
            numRoomsToView: 10,
            showAllRooms: false
        };

        requestParam.availRoomsReq = selectedRooms
            ? selectedRooms
            : null;

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Tour/GetTourAvailability`, requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.tour) {
                isError = false;

                setTourAvailability(response.tour);

                let setDefaultRoom = selectedProductData && (
                    (selectedProductData.SelectedInfo && !selectedProductData.SelectedInfo.ProductSelection) ||
                    (templateData.inclusion === TemplateInclusion.Optional && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.ProductSelection)
                );

                let arrProductSelection = getBestRoomCombination(response.tour);
                let productPriceDetail = getTotalPrice(response.tour, arrProductSelection);

                if (setDefaultRoom || selectedRooms) {
                    // setto la room/best combination di default
                    onUpdateSelectedInfoData(templateData.templateDataId, carpetId, null, arrProductSelection.join("|"), productPriceDetail, true, false);
                }

                let setDefaultAlternativePrice = selectedProductData && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.SelectedTotalPrice;
                if (setDefaultAlternativePrice) {
                    onUpdateSelectedInfoData(templateData.templateDataId, carpetId, null, arrProductSelection.join("|"), productPriceDetail, true, false);
                }
            }
        }

        setIsError(isError);
        if (isError) {
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, true, true);
        }

        setIsLoading(false);
        onFinishSearch(templateData.templateDataId, false);
    }
    const getBestRoomCombination = (tour) => {
        let arrProductSelection = [];

        let roomCombinations = [];
        for (let i = 0; i < tour.roomGroupInfo.length; i++) {
            let roomGroupInfo = tour.roomGroupInfo[i];
            if (i === 0) {
                roomCombinations = roomGroupInfo.roomGroups[0].rooms[0].roomCombinations;
                arrProductSelection.push(roomGroupInfo.roomGroups[0].rooms[0].id);
            } else {
                if (roomCombinations && roomCombinations.length > 0) {
                    let roomSelected = false;
                    for (let j = 0; j < roomGroupInfo.roomGroups.length; j++) {
                        let roomGroups = roomGroupInfo.roomGroups[j];
                        if (roomGroups.rooms && roomGroups.rooms.length > 0) {
                            for (let k = 0; k < roomGroups.rooms.length; k++) {
                                if (roomCombinations[i - 1] === roomGroups.rooms[k].id) {
                                    arrProductSelection.push(roomGroups.rooms[k].id);
                                    roomSelected = true;
                                    break;
                                }
                            }
                        }

                        if (roomSelected)
                            break;
                    }
                } else {
                    arrProductSelection.push(roomGroupInfo.roomGroups[0].rooms[0].id);
                }
            }
        }

        return arrProductSelection;
    }

    const selectAlternativeRoom = (selectedRoomInfo) => {
        if (tourAvailability.roomGroupInfo.length === 1) {
            let selectedRooms = [];
            if (selectedRoomInfo) {
                selectedRooms.push({ id: selectedRoomInfo.id, sequence: selectedRoomInfo.sequence });
            } else {
                selectedRooms = [];
            }
            setAvailRoomsReq(selectedRooms);
            return;
        }
        else {
            let selectedRooms = [...availRoomsReq];
            if (selectedRoomInfo) {
                if (selectedRooms.filter(x => x.id === selectedRoomInfo.id).length > 0) {
                    selectedRooms = selectedRooms.filter(x => x.id !== selectedRoomInfo.id && x.sequence < selectedRoomInfo.sequence);
                }
                else {
                    selectedRooms.push({ id: selectedRoomInfo.id, sequence: selectedRoomInfo.sequence });
                }
            }
            else {
                selectedRooms = [];
            }
            setAvailRoomsReq(selectedRooms);
            getTourAvailability(selectedRooms);
            scrollTo(null, `templateDataId_${templateData.templateDataId}`);
        }
    }
    const selectCombination = (data) => {
        let arrProductSelection = [];
        for (let i = 0; i < availRoomsReq.length; i++) {
            arrProductSelection.push(availRoomsReq[i].id);
        }
        let productPriceDetail = getTotalPrice(tourAvailability, arrProductSelection);
        onUpdateSelectedInfoData(templateData.templateDataId, tourAvailability.carpetId, null, arrProductSelection.join("|"), productPriceDetail, true, false);
        setShowChangeRooms(!showChangeRooms);
        scrollTo(null, `templateDataId_${templateData.templateDataId}`);
    }

    // buttons
    const onButtonSelect = () => {
        // funzione usata quando si seleziona un prodotto opzionale o in alternativa, quindi viene selezionata la room con prezzo piu basso
        let roomIds = getBestRoomCombination(tourAvailability);
        let productPriceDetail = getTotalPrice(tourAvailability, roomIds);
        onSelectAlternative(templateData.templateDataId, selectedProductData.SelectedId, roomIds.join("|"), productPriceDetail, tourAvailability.carpetId);
    }
    const onButtonUnselect = () => {
        onUnselectOptional(templateData.templateDataId);
    }
    const toggleChangeRooms = (e) => {
        if (e) {
            e.preventDefault();
        }

        setAvailRoomsReq([]);
        setShowChangeRooms(!showChangeRooms);
        getTourAvailability();
    }

    // pricing
    const getTotalPrice = (tour, arrProductSelection) => {
        let productPriceDetail = { DisplayPrice: 0, NetPrice: 0, Markup: 0, Fee: 0, Commission: 0 };

        for (let i = 0; i < tour.roomGroupInfo.length; i++) {
            let roomGroupInfo = tour.roomGroupInfo[i];
            for (let j = 0; j < roomGroupInfo.roomGroups.length; j++) {
                let roomGroups = roomGroupInfo.roomGroups[j];
                if (roomGroups.rooms && roomGroups.rooms.length > 0) {
                    for (let k = 0; k < roomGroups.rooms.length; k++) {
                        if (arrProductSelection.includes(roomGroups.rooms[k].id)) {
                            productPriceDetail.DisplayPrice += roomGroups.rooms[k].pricing.displayPrice;
                            productPriceDetail.NetPrice += roomGroups.rooms[k].pricing.displayNet;
                            productPriceDetail.Markup += roomGroups.rooms[k].pricing.agencyMarkupVal;
                            productPriceDetail.Fee += roomGroups.rooms[k].pricing.agencyFee;
                            productPriceDetail.Commission += roomGroups.rooms[k].pricing.commission;
                        }
                    }
                }
            }
        }

        return productPriceDetail;
    }
    const getTotalPriceDiff = (roomGroupInfos) => {
        // funzione usata per calcolare la differenza di prezzo tra la combinazione scelta e la best del prodotto opzionale/in alternativa
        let totalPrice = 0;
        for (let i = 0; i < roomGroupInfos.length; i++) {
            totalPrice += roomGroupInfos[i].roomGroups[0].displayPrice;
        }

        if (!selectedProductData || !selectedProductData.SelectedTotalPrice) {
            if (isOptional) {
                let formattedTotalPrice = formatPrice(totalPrice, roomGroupInfos[0].roomGroups[0].rooms[0].pricing.valutaCliente, cultureName);
                return `+${formattedTotalPrice}`;
            }

            return (<></>);
        }

        let diff = totalPrice - selectedProductData.SelectedTotalPrice.DisplayPrice;
        if (diff === 0)
            return "+ " + formatPrice(diff, roomGroupInfos[0].roomGroups[0].rooms[0].pricing.valutaCliente, cultureName);

        let formattedPrice = formatPrice(diff, roomGroupInfos[0].roomGroups[0].rooms[0].pricing.valutaCliente, cultureName);

        return `${diff > 0 ? "+" : ""}${formattedPrice}`;
    }

    // render
    const renderSelectedRooms = () => {
        if (isError)
            return (<h6 className="red">Prodotto non disponibile.</h6>);

        if (!tourAvailability || !tourAvailability.roomGroupInfo || tourAvailability.roomGroupInfo.length === 0)
            return (<></>);

        let selectedRoomIds = !isAlternative && !isOptional
            ? selectedProductData.SelectedInfo.ProductSelection.split("|")
            : null;

        let selectedRooms = [];
        for (let i = 0; i < tourAvailability.roomGroupInfo.length; i++) {
            for (let j = 0; j < tourAvailability.roomGroupInfo[i].roomGroups.length; j++) {
                let roomGroup = tourAvailability.roomGroupInfo[i].roomGroups[j];
                let matchingRoom = selectedRoomIds
                    ? roomGroup.rooms.filter(x => selectedRoomIds.includes(x.id))
                    : roomGroup.rooms;

                if (!selectedRoomIds && j > 0)
                    break; // se non ho room selezionate, esco al secondo giro in quanto avevo preso la prima room con prezzo minore

                if (matchingRoom.length > 0) {
                    selectedRooms.push({
                        Sequence: roomGroup.sequence,
                        RoomName: roomGroup.roomName,
                        DisplayPrice: roomGroup.displayPrice,
                        PlanName: matchingRoom[0].planName,
                        CancellationPolicy: matchingRoom[0].cancellationPolicy,
                        SupplierName: matchingRoom[0].supplier ? matchingRoom[0].supplier.supplierName : null,
                        status: matchingRoom[0].status
                    });
                }
            }
        }

        if (!isAlternative && !isOptional) {
            return (
                selectedRooms.map((room, idx) =>
                    <React.Fragment key={idx}>
                        <div className="w-100">
                            <label>
                                {t(`Tour:Room`)} <var>{room.Sequence}: {room.RoomName}</var>
                            </label>
                        </div>
                        <div className="w-100">
                            <label>
                                <span className="material-icons icon-10">restaurant</span>
                                <var>{room.PlanName}</var>
                            </label>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label className="penalty">
                                    {room.CancellationPolicy &&
                                        <span className={room.CancellationPolicy.isFree ? "penalty free" : "penalty"}>
                                            <M3Icon iconName="CancelPolicy" externalClass="icon-10 mr-2" />
                                            {room.CancellationPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(room.CancellationPolicy.expirationDate, { twoDigits: true }) : "Cancellazione con penale"}
                                        </span>
                                    }
                                </label>
                            </div>

                            <div className="col-6 text-right">
                                {room.status && room.status === 'OnRequest' && <div className="text-info mr-2">OnRequest</div>}
                                {idx === 0 && <span className="text-warning small d-block mr-2"><var>{room.SupplierName}</var></span>}
                            </div>
                        </div>
                    </React.Fragment>
                )
            );
        }
        else {
            return (
                selectedRooms.map((room, idx) =>
                    <React.Fragment key={idx}>
                        <div className="w-100">
                            <label>
                                {t(`Tour:Room`)} <var>{room.Sequence}: {room.RoomName}</var>
                            </label>
                        </div>
                    </React.Fragment>
                )
            );
        }
    }


    return (
        <>
            {templateData &&
                <React.Fragment>
                    <div id={`templateDataId_${templateData.templateDataId}`} style={{ height: '0' }}>&nbsp;</div>

                    {debug &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <p style={{ width: '500px' }}>
                                {tourAvailability && tourAvailability.name}<br />
                                {selectedProductData && JSON.stringify(selectedProductData)}
                            </p>
                        </div>
                    }

                    {!isAlternative && !isOptional &&
                        <React.Fragment>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadTemplateStep()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="myBook-card-body">
                                    <div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                <span><data m3lab="Product.Tour.Item">{t(`Product:Tour:Item`)}</data></span>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4">
                                                <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                <div className="btn-group text-right float-end pr-2">
                                                    <button id={templateData.templateDataId} className="btn p-0 m-0" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <data m3ico="MenuFunctions">
                                                            <M3Icon iconName="MenuFunctions" />
                                                        </data>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="menuItem1">
                                                        <li>
                                                            <a id={`aStructDetail_${templateData.product.Id}`} href="#" onClick={e => scrollTo(e, "tourDetail_" + templateData.product.Id)} className="dropdown-item">
                                                                <data m3ico="Info icon-10"><span className="material-icons icon-10"> <M3Icon iconName="Info" externalClass="icon-10 small text-gray-600" /></span></data>
                                                                <label><data m3lab="ActionMenu.detail">{t(`ActionMenu:Detail`)}</data></label>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a id={`aStructChangeRoom_${templateData.product.Id}`} href="#" onClick={e => toggleChangeRooms(e)} className="dropdown-item">
                                                                <data m3ico="Night"><M3Icon iconName="Night" externalClass="icon-10 small text-gray-600" /></data>
                                                                <label><data m3lab="ActionMenu.RoomChange">{t(`ActionMenu:RoomChange`)}</data></label>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="h5">
                                            <var>{templateData.product.name}</var>
                                        </div>

                                        {/* Best or selected room */}
                                        {isLoading
                                            ? (<div><div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div>{t(`Template:LoadingRooms`)}</div>)
                                            : (<>{renderSelectedRooms()}</>)
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Alternative Rooms */}
                            {!isLoading && tourAvailability && showChangeRooms && (
                                <>
                                    <div className="m-0 p-2">
                                        {tourAvailability.roomGroupInfo && tourAvailability.roomGroupInfo.length > 0 && tourAvailability.roomGroupInfo.map((roomGroup, accomodationIdx) =>
                                            <React.Fragment key={accomodationIdx}>
                                                <div>
                                                    <div className="row p-1">
                                                        <div className="h5 col-12 col-lg-5">
                                                            <data m3lab="Product.Tour.Accomodation">{t(`Product:Tour:Accomodation`)} <var>{roomGroup.sequence}</var></data>
                                                        </div>
                                                    </div>
                                                    {roomGroup.roomGroups && roomGroup.roomGroups.map((room, idx) =>
                                                        <TourRoom
                                                            key={idx}
                                                            room={room}
                                                            roomIndex={idx}
                                                            showPriceBar={false}
                                                            showCost={false}
                                                            totalAccomodation={tourAvailability.roomGroupInfo.length}
                                                            handleMealPlanIdAndSequence={selectAlternativeRoom}
                                                            multiRoomSelected={availRoomsReq}
                                                            handleFinalBook={selectCombination}
                                                            templateInfo={{ templateId: templateData.templateId, selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null }}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </>
                            )}

                            {/* Button Remove when Optional */}
                            {!isLoading && templateData.inclusion === TemplateInclusion.Optional && (
                                <>
                                    <div>
                                        <div className="float-end small text-blue">
                                            <button id={`btnUnselect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonUnselect()}>
                                                <span className="text-danger">
                                                    <data m3lab="Template.Delete">Rimuovi</data>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    }

                    {(isAlternative || isOptional) && !isLoading && !isError &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                                <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                    <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl && templateData.product.thumbUrl.length > 0 ? encodeURI(templateData.product.thumbUrl[0]) : imageOnLoadTemplateStep()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}></div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                    <div className="w-100">
                                        <div className="h6 m-0">
                                            <var>{templateData.product.name}</var>
                                        </div>

                                        {!isLoading && renderSelectedRooms()}
                                    </div>
                                </div>
                                {!isError &&
                                    <div className="col-3 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-right float-end pr-0 align-middle h-100">
                                        <button id={`btnSelect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonSelect()}>
                                            {isLoading && <><div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div>{t(`Template:LoadingAlternatives`)}</>}

                                            {!isLoading && tourAvailability && tourAvailability.roomGroupInfo && tourAvailability.roomGroupInfo.length > 0 &&
                                                <React.Fragment>
                                                    <data m3lab="Template.Option">{isAlternative ? t(`Template:Option`) : t(`Template:Add`)}</data>
                                                    <div>
                                                        {getTotalPriceDiff(tourAvailability.roomGroupInfo)}
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </React.Fragment>
            }
        </>
    );
}
