import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import configData from "../../appsettings.json";
import { AccountBalancePrintButton } from "./print/AccountBalance/AccountBalancePrintButton";

export const AccountBalancePerBookAutoDownload = () => {
    let { orderNumber } = useParams();
    let { wsBookingId } = useParams();
    let { userType } = useParams();
    let { visualizationType } = useParams();
    let { grouped } = useParams();
    let { cultureName } = useParams()
    let { authcode } = useParams()
    const [HeaderData, setHeaderData] = useState([]);
    const [DocOrderDetailData, setDocOrderDetailData] = useState([]);
    const [TitleData, setTitleData] = useState([]);
    const [order, setOrder] = useState({ pratiche: [] });
    const [beginData, setBeginData] = useState([]);
    const [endData, setEndData] = useState([]);
    const [scadenziario, setScadenziario] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadData = async (data) => {
            const response = await fetch(configData.Settings.CommonApi_BaseUrl + 'document/GetAccountBalance/' + data.OrderNumber + '/' + data.WsBookingId + '/' + data.UserType + '/' + data.VisualizationType + '/' + data.Grouped + '/' + data.CultureName + '/' + data.AuthCode, { credentials: 'include' });
            if (!response.ok) return;

            const jresponse = await response.json();
            setOrder(jresponse);
            var hd = jresponse.header;
            hd.isCommissionable = jresponse.isCommissionable;
            setHeaderData(hd);
            setTitleData(jresponse.title);
            setBeginData({ section: "BEGIN" });
            setEndData({ section: "END" });
            setDocOrderDetailData(jresponse);
            setIsLoading(false);
        }
        loadData({ OrderNumber: orderNumber, WsBookingId: wsBookingId, VisualizationType: visualizationType, UserType: userType, Grouped: grouped, CultureName: cultureName, AuthCode: authcode }).catch(console.error);


        const response = callGetTimetableDetail();
        if (!response.ok) return;
    }, []);


    useEffect(() => {
        if (DocOrderDetailData && DocOrderDetailData.pratiche && scadenziario && scadenziario.deadlineGroups) {
            setIsLoading(true);
            callPrintPDFFile();
        }
    }, [scadenziario, DocOrderDetailData]);

    async function callGetTimetableDetail() {
        let withOrderDetails = grouped === 'false';
        var method = 'document/GetPaymentsTimetable/' + orderNumber + '/' + withOrderDetails;
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + method, { credentials: 'include' });

        const response = await fetchedRes.json();

        setScadenziario(response);
        setIsLoading(false);
    }

    function callPrintPDFFile() {
        let myButton = document.getElementById('printAccountBalance');
        myButton.click();
    }

    return (
        <>
            {
                <AccountBalancePrintButton
                    headerData={HeaderData}
                    titleData={TitleData}
                    order={order}
                    visualizationType={visualizationType}
                    userType={userType}
                    grouped={grouped}
                    cultureName={cultureName}
                    scadenziario={userType !== "U" ? scadenziario : null}
                />
            }
        </>
    );
}
