import React from 'react';
import { getIconUrlToPrint } from "../../../js/PrintUtility";
import { getCDNLogoDocument } from "../../../js/CDNUtility";

export const HeaderPDFPrint = ({ title, extraInfo }) => {
    return (<>
        <div style={{ paddingBottom: '10px' }}>
            <table>
                <tr className="col-lg-12">
                    <td className="col-lg-5">
                    </td>
                    <td className="col-lg-8" style={{ borderLeft: '1px solid #DAB451' , marginTop: '10px'}}>
                        <img src={getCDNLogoDocument()} className="logo-header" alt="Going Logo" />
                    </td>
                    <td className="col-lg-2">
                    </td>
                    <td className="col-lg-2" style={{borderTop: '15px solid #DAB451', borderRight: '15px solid #DAB451', }}>
                    </td>
                </tr>
                <tr>
                    <td style={{ minHeight: '25px' }}>
                    </td>
                </tr>
            </table>
        </div>
    </>
    )
}
